.arrow {
  &:focus,
  &:hover {
    --transform-arrow: translateX(40%);
    --transition-arrow: $trans-default;
  }
}

.arrow__element {
  display: inline-block;

  transition: $trans-default;
}

.arrow__element svg {
  width: 72px;
  height: 17px;
  overflow: visible;

  fill: currentColor;
}

.arrow__element .arrow__transform {
  transition: $trans-default;
}

.arrow:hover .arrow__transform,
.arrow:focus .arrow__transform {
  transform: translateX(40%);
}

// .arrow:hover .arrow__svg,
// .arrow:focus .arrow__svg {
//   --transform-arrow: translateX(40%);
//   --transition-arrow: all 0.2s ease-in-out;
// }
