.footer {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  padding: 50px 0;

  color: rgb(255, 255, 255);

  background-color: #000000;
  background-image: url("../img/svg/footer-pattern.svg");
  background-repeat: repeat-y;
  background-position: left;
  background-size: 3%;

  transition: all 0.1s ease-in-out 0s;

  @media (max-width: 540px) {
    position: static;

    background-size: 5%;
  }
}

.footer .container {
  padding: 0 5%;
}

.footer__nav {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
