// Colors
// ---------------------------------

// Default

$color-default-black: #000000;
$color-default-white: #ffffff;
$color-transparent: rgba(255, 255, 255, 0);

// Project palette
// для нейминга цветов используем https://www.htmlcsscolor.com/hex/334482

$color-neon-blue: #2c39f2;
$color-torch-red: #ff1553;
$color-main: #665aff;
$color-link: #352a84;
$color-svg-hover: #a42384;
$color-gradient-main: linear-gradient(to left, #ffdc00, #d40115 20%, #a42384 40%, #352a84 60%, #009993 87%, #a1c632);

$color-gradient-base: linear-gradient(to left, #000000, #000000);
// Gradient

$black-to-right: linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%);

$border-radius-main: 100px;
$border-size: 3px;

// Typography
// ---------------------------------

// переменная используемая в html для подключения скейлинга
$fz-default: 16px;

// у некоторых шрифтов в ios возникают проблемы с символом рубля
// https://www.artlebedev.ru/kovodstvo/sections/159/#13
$ff-rouble: "Rouble", "Arial", sans-serif;
$ff-placeholder: "Placeholder", "Arial", sans-serif;
$ff-roboto: "Roboto", "Candara", sans-serif;
$ff-gotham-pro: "Gotham Pro", "Candara", sans-serif;
$ff-tilda: "Tilda", "Helvetica", sans-serif;

// Animation
// ---------------------------------

$tf-default: ease;
$trans-default: 0.3s $tf-default;

// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);

$trans-600: 0.6s $tf-default;

// Viewports
// ---------------------------------

$vp-320: 320px;
$vp-375: 375px;
$vp-768: 768px;
$vp-1024: 1024px;
$vp-1280: 1280px;
$vp-1440: 1440px;
$vp-1920: 1920px;

// Retina
// ---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
