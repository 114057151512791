.form {
  position: relative;

  padding-left: 5%;

  @media (max-width: 900px) {
    padding-left: 0;
  }
}

.form form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 35px;

  @media (max-width: 900px) {
    gap: 25px;
  }
}

.form__group {
  position: relative;

  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 5px 20px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.form__group [data-lastpass-icon-root="true"] {
  display: none;
}

.form__group label {
  width: 100%;
  max-width: 200px;
  margin-top: 6px;

  font-weight: 700;
  text-align: right;

  @media (max-width: 900px) {
    text-align: left;
  }
}

.form__group input:not([type="checkbox"]),
.form__group textarea {
  width: 100%;
  max-width: 320px;
  padding: 10px;

  font-family: inherit;
  font-size: 22px;
  font-style: italic;
  line-height: 26px;
  color: #000000;

  background-color: transparent;
  border: 2px solid #000000;

  transition: border 1s all ease;

  @include hover-focus {
    border-image: $color-gradient-main;
    border-image-slice: 1;
    outline: none;
  }

  @media (max-width: 900px) {
    max-width: 100%;
  }
}

.form__group textarea {
  height: 165px;

  resize: none;
}

.form__group--checkbox {
  grid-template-columns: 200px auto 1fr;

  &::before {
    content: "";

    width: 100%;
    max-width: 200px;

    @media (max-width: 900px) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    flex-direction: row;
    gap: 10px;
  }
}

.form__group--checkbox label {
  max-width: 320px;
  margin-top: 0;

  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.form__group--checkbox input[type="checkbox"] {
  width: 22px;
  height: 22px;

  appearance: none;
  border: 2px solid #000000;

  @include hover-focus {
    border-image: $color-gradient-main;
    border-image-slice: 1;
    outline: none;
  }
}

.form__group--checkbox input[type="checkbox"]:checked {
  background-image: url("../img/svg/icon-check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
}

.form__group--capcha {
  div {
    position: relative;

    display: flex;
    flex-direction: column;
  }

  label {
    grid-row: auto / span 2;
  }

  img {
    display: block;
    width: 100%;
    max-width: 200px;
    height: auto;

    border: 2px solid #000000;

    @include hover-focus {
      border-image: $color-gradient-main;
      border-image-slice: 1;
      outline: none;
    }
  }

  // .input-message {
  //   display: none;
  // }

  // .input-message.is-invalid {
  //   display: block;
  // }
  .input-message-capcha {
    position: absolute;
    top: 100%;
    right: 0;
    transform: translateY(-100%);
    // max-width: 50%;

    display: inline-block;

    font-size: 16px;
    line-height: 20px;

    // @media (min-width: 901px) {
    //   left: 540px;
    //   transform: translateX(-100%);
    // }

    @media (max-width: 900px) {
      position: absolute;
      top: 100%;
      right: 0;

      font-size: 14px;
      text-align: right;
    }

    // @media (min-width: 901px) {}
    // // position: absolute;
  }
}

.form__submit {
  margin-right: auto;
  margin-left: 220px;

  @media (max-width: 900px) {
    margin: 0 auto;
  }
}

.form__group.is-valid input,
.form__group.is-valid input:-internal-autofill-selected,
.form__group.is-valid textarea,
.form__group.is-valid label,
.form__group.valid input,
.form__group.valid input:-internal-autofill-selected,
.form__group.valid textarea,
.form__group.valid label,
.form__group.is-valid .input-message,
.form__group.valid .input-message-capcha {
  color: #12a312 !important;
  -webkit-text-fill-color: #12a312 !important;

  border-color: rgba(71, 184, 71, 0.8);
}

.form__group.is-invalid input,
.form__group.is-invalid input:-internal-autofill-selected,
.form__group.is-invalid textarea,
.form__group.is-invalid label,
.form__group.invalid input,
.form__group.invalid input:-internal-autofill-selected,
.form__group.invalid textarea,
.form__group.invalid label,
.form__group.is-invalid .input-message,
.form__group.invalid .input-message-capcha {
  color: rgba(234, 26, 26, 0.8) !important;

  border-color: rgba(234, 26, 26, 0.8);
}

.feedback .input-message {
  position: absolute;
  top: 100%;
  left: 220px;

  display: inline-block;
  align-self: start;

  font-size: 16px;
  line-height: 20px;

  @media (max-width: 900px) {
    left: 0;

    max-width: 50%;

    font-size: 14px;
  }
}

.form.is-send form {
  display: none;
}

.form__message {
  display: none;
  margin: auto;

  text-align: center;

  .form.is-success &[data-message-success] {
    display: block;
  }

  .form.is-error &[data-message-error] {
    display: block;
  }
}

// Preloader
// is-sending

.form__preloader {
  position: absolute;
  z-index: 1000;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;
  width: 100px;
  height: 100px;

  color: #3749a2;

  fill: #3749a2;
}

.form.is-sending .form__preloader {
  display: block;
}

.form__preloader svg {
  animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

div[data-lastpass-icon-root] {
  display: none;
}
