.button {
  position: relative;

  display: inline-block;
  padding: 10px 40px;

  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  font-family: $ff-tilda;
  text-align: center;
  color: $color-default-white;

  background-color: $color-default-black;
  border: none;
  border-radius: $border-radius-main;
  cursor: pointer;

  transition: $trans-default;

  & span {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    background-image: $color-gradient-main;
    border-radius: $border-radius-main;
    visibility: hidden;
    opacity: 0;

    transition: $trans-default;
  }

  &--red {
    background-color: $color-torch-red;
  }

  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      &::after {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.focus-visible:focus {
    &::after {
      visibility: visible;
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }
}
