.about {
  padding: 70px 0;
}

.about__wrapper-text {
  padding: 0 8%;

  @media (max-width: 540px) {
    padding: 0;
  }
}

.about__title {
  margin-bottom: 30px;
}

.about__subtitle {
  margin-bottom: 10px;
}

.about p {
  margin: 0 0 40px;
}

.about__item:nth-child(2n) {
  text-align: right;

  @media (max-width: 540px) {
    text-align: left;
  }
}

.about__img {
  max-width: 600px;
  margin: 0 auto;
}

.about__img img {
  width: 100%;
}
