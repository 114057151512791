.nav {
  margin-left: auto;
}

.nav__toggle {
  position: absolute;
  top: 0;
  right: 20px;

  display: none;
  width: 35px;
  height: 35px;
  padding: 0;

  background-color: transparent;
  border: none;
  border-top: 5px solid #000000;
  border-bottom: 5px solid #000000;

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: 0;

    width: 100%;
    height: 5px;

    background-color: #000000;
  }

  &::before {
    top: 5px;
  }

  &::after {
    bottom: 5px;
  }

  @media (max-width: 768px) {
    display: block;
  }

  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      border-image: $color-gradient-main;
      border-image-slice: 1;

      &::after,
      &::before {
        background-image: $color-gradient-main;

        transition: $trans-default;
      }
    }
  }

  &.focus-visible:focus {
    border-image: $color-gradient-main;
    border-image-slice: 1;

    &::after,
    &::before {
      background-image: $color-gradient-main;

      transition: $trans-default;
    }
  }
}

.nav__list {
  display: flex;
  gap: 20px;
}

.nav__link {
  font-weight: 700;
  color: inherit;

  @include hover-focus {
    background-image: $color-gradient-main;
    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;
  }
}

.nav--main .nav__list {
  @media (max-width: 768px) {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 50px;

    color: #ffffff;

    background-color: #000000;
    visibility: hidden;
    opacity: 0;
  }
}

// .nav--open .nav__toggle {
//   right: 35px;
// }

.nav--open .nav__list {
  visibility: visible;
  opacity: 1;
}

.nav--open .nav__toggle {
  border-color: transparent;

  &::after {
    bottom: 10px;

    transform: rotate(45deg);
  }

  &::before {
    top: 10px;

    transform: rotate(-45deg);
  }

  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      border-image: none;

      &::after,
      &::before {
        background-image: $color-gradient-main;

        transition: $trans-default;
      }
    }
  }

  &.focus-visible:focus {
    border-image: none;

    &::after,
    &::before {
      background-image: $color-gradient-main;

      transition: $trans-default;
    }
  }
}
