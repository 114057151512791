.say-hello {
  padding: 50px 0 50px 8%;

  font-size: 22px;
  line-height: 33px;
}

.say-hello p {
  margin: 0;

  & + p {
    margin-top: 0;
  }
}
