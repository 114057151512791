// не нужно добавлять свои миксины для ресета списков, обнуления кнопок и прочие
// этим вы усложняете работу команды, проверку вашего кода и будущую поддержку проекта

@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// @include retina-bg("../img/header-bg", "png");
@mixin retina-bg($img_path, $file_ext: "jpg") {
  background-image: url("#{$img_path}.#{$file_ext}");

  @supports (background-image: url("#{$img_path}.webp")) {
    background-image: url("#{$img_path}.webp");
  }

  @supports (background-image: url("#{$img_path}.avif")) {
    background-image: url("#{$img_path}.avif");
  }

  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    background-image: url("#{$img_path}@2x.#{$file_ext}");

    @supports (background-image: url("#{$img_path}@2x.webp")) {
      background-image: url("#{$img_path}@2x.webp");
    }

    @supports (background-image: url("#{$img_path}@2x.avif")) {
      background-image: url("#{$img_path}@2x.avif");
    }
  }
}

@mixin font($font_name, $file_name, $weight, $style) {
  font-style: #{$style};
  font-weight: #{$weight};
  font-family: $font_name;

  font-display: swap;
  src: url("../fonts/#{$file_name}.woff2") format("woff2"), url("../fonts/#{$file_name}.woff") format("woff");
}

@mixin fontFolder($font_name, $file__direct, $file_name, $weight, $style) {
  font-style: #{$style};
  font-weight: #{$weight};
  font-family: $font_name;

  font-display: swap;
  src: url("../fonts/#{$file__direct}/#{$file_name}.woff2") format("woff2"),
    url("../fonts/#{$file__direct}/#{$file_name}.woff") format("woff");
}

@mixin hover {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }
}

@mixin focus {
  &.focus-visible:focus {
    @content;
  }
}

@mixin active {
  &.focus-visible:active {
    @content;
  }
}

@mixin hover-focus {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }

  &.focus-visible:focus {
    @content;
  }
}

// Desktop first

@mixin vp-1919 {
  @media (max-width: ($vp-1920 - 1px)) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: ($vp-1440 - 1px)) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: ($vp-1280 - 1px)) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: ($vp-1024 - 1px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: ($vp-768 - 1px)) {
    @content;
  }
}

@mixin vp-374 {
  @media (max-width: ($vp-375 - 1px)) {
    @content;
  }
}
