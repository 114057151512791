.container {
  width: 100%;
  min-width: 320px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 30px;

  @include vp-1439 {
    padding: 0 32px;
  }
  @include vp-1023 {
    padding: 0 24px;
  }
  @include vp-767 {
    padding: 0 16px;
  }
}
