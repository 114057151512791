.services {
  padding: 50px 0;
}

.services__text {
  max-width: 650px;
  margin: 0 auto 50px;
}

.services__title {
  margin-bottom: 50px;

  @media (max-width: 540px) {
    margin-bottom: 30px;
  }
}

.services__item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 50px;
  align-items: start;
  margin-bottom: 100px;

  &:nth-child(2n) {
    grid-template-columns: 1fr auto;
  }

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 50px;
  }

  &--2:hover {
    --services-circles-2: #a42384;
  }

  &--3:hover {
    --services-circles-3: #a42384;
  }

  &--4:hover {
    --services-circles-4: #a42384;
  }

  &--5:hover {
    --services-circles-5: #a42384;
  }

  &--6:hover {
    --services-circles-6: #a42384;
  }

  &--7:hover {
    --services-circles-7: #a42384;
  }

  &--8:hover {
    --services-circles-8: #a42384;
  }

  &--9:hover {
    --services-circles-9: #a42384;
  }

  &--10:hover {
    --services-circles-10: #a42384;
  }
}

.services__img {
  width: 100%;
  max-width: 400px;

  @media (max-width: 1024px) {
    max-width: 200px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.services__img img,
.services__img svg {
  display: block;
  width: 100%;
  height: auto;
}

.services__subtitle {
  margin: 0 0 20px;

  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  font-family: $ff-tilda;

  @media (max-width: 320px) {
    font-size: 25px;
    line-height: 30px;
  }
}

.services__descript {
  padding-top: 50px;

  @media (max-width: 1024px) {
    padding-top: 0;
  }

  &[open] .services__btn {
    margin-bottom: 30px;

    color: $color-svg-hover;

    background-image: $color-gradient-main;
    -webkit-background-clip: text;
            background-clip: text;

    -webkit-text-fill-color: transparent;
  }
}

.services__item:nth-child(2n) .services__descript {
  order: -1;
}

.services__item-hide h3,
.services__item-hide h4 {
  margin: 0 0 20px;
}

.services__item-hide ul {
  padding-left: 20px;
}

.services__item-hide a {
  color: $color-link;
  text-decoration: underline;

  transition: $trans-default;

  @include hover-focus {
    background-image: $color-gradient-main;
    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;
  }
}

.services__descript summary {
  display: flex;
  flex-direction: column;
  align-items: start;

  list-style: none;

  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  &::-moz-list-bullet {
    list-style-type: none;
  }

  &::marker {
    display: none;
  }

  &:hover,
  &:focus {
    color: $color-svg-hover;

    background-image: $color-gradient-main;
    -webkit-background-clip: text;
            background-clip: text;

    transition: $trans-default;

    -webkit-text-fill-color: transparent;
  }
}

.services__descript[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
