.transition {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  // display: none;

  width: 100%;
  height: 100vh;

  background-image: linear-gradient(
    to left,
    rgb(255, 220, 0),
    rgb(212, 1, 21) 20%,
    rgb(164, 35, 132) 40%,
    rgb(53, 42, 132) 60%,
    rgb(0, 153, 147) 87%,
    rgb(161, 198, 50)
  );
  transform: translateX(100%);

  animation: loading 0.7s linear;

  animation-fill-mode: forwards;
}

@keyframes loading {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
