.phrase {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.phrase .logo {
  text-transform: lowercase;

  @media (max-width: 320px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.phrase p {
  margin: 0;

  font-weight: 800;
  font-size: 35px;
  line-height: 35px;

  @media (max-width: 540px) {
    font-size: 20px;
    line-height: 25px;
  }

  @media (max-width: 320px) {
    font-size: 14px;
    line-height: 20px;
  }
}
