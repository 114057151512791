.contacts {
  padding: 50px 0;
}

.contacts .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 50px;
  align-items: start;

  @media (max-width: 540px) {
    display: block;
  }
}

.contacts__img {
  grid-column: -1/-2;
  grid-row: 1 / span 2;
}

.contacts__img img {
  display: block;
  width: 100%;
}
