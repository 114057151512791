.feedback {
  padding: 150px 0 100px;

  @media (max-width: 768px) {
    padding-top: 100px;
  }
}

.feedback__subtitle {
  margin-bottom: 50px;
}
