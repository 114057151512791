.logo {
  position: relative;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  align-items: center;
  width: fit-content;

  font-weight: 800;
  font-size: 35px;
  line-height: 35px;
  color: inherit;
  text-transform: uppercase;

  transition: $trans-default;

  @include hover-focus {
    color: $color-svg-hover;

    background-image: $color-gradient-main;
    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;

    &::after,
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }

  @media (max-width: 540px) {
    font-size: 20px;
    line-height: 25px;
  }

  // &::after,
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 3px;
  //   z-index: -1;

  //   width: 30px;
  //   height: 30px;

  //   background-color: #a42384;
  //   background-image: $color-gradient-main;
  //   border-radius: 50%;
  //   visibility: hidden;
  //   opacity: 0;

  //   transition: $trans-default;
  // }

  // &::after {
  //   left: 25px;
  // }

  // &::before {
  //   left: 3px;
  // }
}

.logo span {
  display: inline-block;
}

.logo svg {
  width: 60px;
  height: 30px;

  fill: currentColor;
}
