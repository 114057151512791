.title {
  margin: 0;

  font-size: 44px;
  line-height: 50px;

  @media (max-width: 540px) {
    font-size: 40px;
    line-height: 45px;
  }
}

.subtitle {
  margin: 0;

  font-size: 33px;
  line-height: 40px;

  @media (max-width: 540px) {
    font-size: 25px;
    line-height: 30px;
  }
}
