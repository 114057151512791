.decor {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: -15px;

  & svg {
    width: 100%;
    height: 100%;

    fill: currentColor;
  }

  @media (max-width: 540px) {
    width: 10px;
    height: 10px;
    margin-left: -10px;
  }
}

.decor--comma {
  height: 20px;
}

.decor--letter-o {
  width: 30px;
  height: 30px;

  @media (max-width: 540px) {
    width: 20px;
    height: 20px;
  }
}
