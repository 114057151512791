.intro .container {
  position: relative;

  padding-top: 150px;
  padding-bottom: 180px;

  @media (max-width: 540px) {
    padding-bottom: 80px;
  }
}

.intro__title {
  z-index: 1;

  display: inline-block;
  max-width: 900px;
  margin-bottom: 40px;

  font-weight: 800;
  font-size: 80px;
  line-height: 90px;
  vertical-align: baseline;
  text-shadow: 0 0 3px #ffffff;

  @media (max-width: 540px) {
    font-size: 40px;
    line-height: 50px;
  }
}

.intro__delimiter {
  display: inline-block;
}

.intro__phrase {
  margin-bottom: 40px;
}

.intro__video {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;

  width: 60%;

  @media (max-width: 540px) {
    display: none;
  }
}

.intro__video video {
  width: 100%;
}
