.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  padding: 20px 0;

  background-color: rgba(#ffffff, 0.8);
}

.header .container {
  position: relative;

  display: grid;
  grid-template-columns: 1fr auto;
  gap: 30px;
}
