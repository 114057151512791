.line-gradient {
  position: relative;

  padding-bottom: 30px;

  scroll-margin-top: 70px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 20px;

    background-image: $color-gradient-main;
  }
}
